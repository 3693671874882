/*
Template Name: Eatreal - Online Grocery Supermarket Mobile Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/

/*
-- Body
-- Colors
-- Buttons
-- Typography
-- Navbar
-- Homepage
-- Banners
-- Product Detail
-- Listing
-- My Account
-- Checkout
-- Mobile Media
*/

/* Bootstrap Icons */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");

/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Gotham-Medium';
    src: local('GothamMedium'), url(../fonts/GothamMedium.ttf) format('truetype'); 
}

@font-face {
    font-family: 'Gotham-Bold';
    src: local('GothamBold'), url(../fonts/GothamBold.ttf) format('truetype'); 
}

@font-face {
    font-family: 'Gotham-Book';
    src: local('GothamBold'), url(../fonts/GothamBook.ttf) format('truetype'); 
}

:root {
    --primary: #0096c7;
    --danger: #F0B600;
    --danger-hover:#593dff;
    --warning: #ff8d27;
    --info: #1D71B8;
    --muted: #e9ecef;
    --light-dark: #444444;
	--toastify-color-progress-dark: #1D71B8!important;
}

/* Body */
body {
    font-family: 'Gotham-Medium', sans-serif;
    font-size: 13px;
	-webkit-font-smoothing: antialiased;
}
/* Scroll Bar */
html ::-webkit-resizer {
    background-color: #ffffff;
}
html ::-webkit-scrollbar {
    width: 0px;
    overflow: visible;
}
html ::-webkit-scrollbar-button {
    display: none;
}
html ::-webkit-scrollbar-corner {
    background-color: #ffffff;
}
html ::-webkit-scrollbar-thumb {
    background-color: rgba(138, 138, 138, 0.4);
    border-radius: 7px;
}
html ::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
a {
    text-decoration: none;
    color: var(--danger);
}
.form-control, .form-select, .form-check-input {
    box-shadow: none !important;
}
.offcanvas-footer{
    padding: 1rem;
}
.form-check-input:checked{
    background-color: var(--info) !important;
    border-color: var(--info) !important;
}
.accordion .accordion-button{
    box-shadow: none;
    background-color: #fff;
    color: #000;
}
.dropdown .dropdown-menu .dropdown-item:active{
    background-color: var(--danger);
}
.osahan-dropdown {
    font-size: 14px;
    min-width: 256px;
}
.osahan-dropdown .dropdown-item {
    padding: 6px 1rem;
    font-size: 14px;
}
.shop-category,.popular-products,.coupons,.cold-drinks,.top-picks,.big-img,.small-img,.hero-slider {
    display: none;
}
.intro-logo {
    height: 80px;
}

.user-icon {
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
div#addaddress .ratio.ratio-1x1.h-100 {
    height: 200px !important;
}
div#addaddress .ratio::before {
    display: none;
}
/* Colors */
.bg-primary{
    background-color: #573cff;
    background-image: linear-gradient(43deg, #141516 0%, #1D71B8 100%);
}
.text-danger{
    color: var(--danger) !important;
}
.bg-danger{
    background-color: var(--danger) !important;
}
.border-danger{
	border: 1px solid var(--danger) !important;
}
.border-end, .border, .border-start, .border-top, .border-bottom, .card, .card-header {
    border-color: #eaecef !important;
}

/* Buttons */
.osahan-arrow {
    width: 35px;
    height: 35px;
    background: #fff;
    color: #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50px;
    top: 0;
    bottom: 0;
    font-size: 20px;
    border: 1px solid #dbdbdb;
    margin: auto;
    cursor: pointer;
    transition: all 0.2s;
}
.osahan-arrow:hover {
    border-color: #6a41f4;
    background: #6a41f4;
    color: #ffffff;
}
.osahan-right {
    right: -11px;
}
.osahan-left {
    z-index: 9;
    left: -10px;
}
.shop-category .osahan-arrow {
    bottom: auto;
    top: 41px;
}
.top-picks .osahan-arrow {
    bottom: auto;
    top: 59px;
}
.big-img .osahan-left {
    left: 20px;
}
.big-img .osahan-right {
    right: 20px;
}
.small-img .slick-current.slick-active .border {
    border:2px solid #d72900 !important;
}
.small-img .border{
    cursor: pointer;
    transition: all 0.2s;
}
.small-img .border:hover{
    border:2px solid #d72900 !important;
}
.osahan-new-btn {
    padding: 6px 0;
    border-radius: 11px !important;
}
.btn-outline-danger{
    border-color: var(--danger) !important;
    color: var(--danger) !important;
}
.btn-outline-danger:hover{
    background-color: var(--danger) !important;
    color: #fff !important;
}
.btn-danger {
    background-color: var(--danger) !important;
    border-color: var(--danger) !important;
}
.value{
    width: 100px;
    border: none !important;
}
.value .btn {
    font-size: 22px;
    padding: 0px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    border: 1px solid #cae9ff !important;
    min-width: 32px;
    background: #dbeeff;
    border-radius: 50px !important;
    color: #1d71B8;
    font-weight: 100;
    transition: all 0.2s;
}
.value .btn:hover, .value .btn:focus {
    background: var(--info) !important;
    color: #fff;
    border-color: #593dff !important;
}

/* Typography */
.small-sm{
    font-size: 10px;
}
.fw-bold {
    font-family: 'Gotham-Bold';
	font-weight: 100!important;
}
.h6, h6 {
    font-size: 15px;
}

/* Navbar */
.navbar .navbar-brand {
    padding-bottom: .2rem;
    padding-top: 0
}
.navbar .navbar-toggler {
    padding: 4px 5px 0px 6px;
}
.navbar .navbar-toggler:focus {
    box-shadow: none;
    outline: 0
}
.navbar .navbar-collapse .navbar-toggler {
    position: absolute;
    right: .5rem;
    top: .6rem;
    z-index: 1
}
@media (max-width: 992px) {
    .navbar .navbar-nav .nav-item .nav-link {
        color: #595c5f;
        padding: 11px 0 !important;
    }
    .navbar .navbar-nav .nav-item .nav-link:after {
        float: right;
        margin-top: 7px;
    }
    .navbar .offcanvas {
        overflow: auto;
    }
}
.navbar .dropdown-fullwidth {
    position: static
}
.navbar .dropdown-fullwidth .dropdown-menu {
    left: 0;
    margin: auto;
    max-width: 1290px;
    padding: 1rem 0;
    right: 0;
}
.navbar .dropdown .dropdown-toggle:after {
    background-repeat: no-repeat;
    border: none;
    content: "\eb11";
    font: normal normal normal 0.9em/1 'lineicons';
    margin-left: 0.375rem;
    vertical-align: middle;
}
.navbar .dropdown-menu {
    border-radius: .5rem;
    box-shadow: 0 0 2px rgba(145,158,171,.2),0 12px 24px -4px rgba(145,158,171,.12);
    color: var(--fc-gray-600);
    line-height: 1.2rem;
    min-width: 12rem;
}
.navbar .dropdown-menu .dropdown-list-group-item {
    min-width: 100%
}
.navbar .dropdown-menu .dropdown-submenu a:after {
    position: absolute;
    right: 1rem;
    transform: rotate(-90deg)
}
.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
    left: 100%;
    min-width: 12rem;
    right: 0;
    top: -18px
}
.navbar .dropdown-menu .dropdown-submenuBig a:after {
    position: absolute;
    right: 1rem;
    transform: rotate(-90deg)
}
.navbar .dropdown-menu .dropdown-submenuBig.dropend .dropdown-menu {
    left: 105%;
    min-width: 12rem;
    right: 0;
    top: -35px
}
.navbar .navbar-right-wrap .list-group-item {
    padding: 1rem 1.25rem
}
.navbar .nav-badge {
    position: absolute;
    right: 3px;
    top: -9px
}
@media (min-width: 1200px) {
    .navbar-default .dropdown-menu {
        display:block;
        opacity: 0;
        transform: translateY(20px);
        transition: all .3s ease-in;
        visibility: hidden
    }
    .navbar-default .dropdown-menu-end {
        left: auto;
        right: 0
    }
    .navbar-default .dropdown-menu-start {
        left: 0;
        right: auto
    }
    .navbar-default .dropdown-submenu:hover>.dropdown-menu,.navbar-default .dropdown-submenuBig:hover>.dropdown-menu,.navbar-default .dropdown:hover>.dropdown-menu {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible
    }
}
.navbar-nav-offcanvac .nav-item .nav-link {
    border-bottom: 1px solid #eaecef;
}
.navbar-dark .navbar-nav .nav-link {
    border-bottom: transparent
}
.navbar-toggler-icon {
    height: 1.2em
}
.dropdown-menu {
    box-shadow: 0 0 2px rgba(145,158,171,.2), 0 12px 24px -4px rgba(145,158,171,.12);
    padding: 10px;
    border-color: #eaecef;
}
.dropdown-item {
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 8px;
    color: #656565;
}
.osahan-header a.p-3 {
    height: 68px;
}
.logo-img{
    height: 36px;
}
.osahan-location small.small {
    width: 198px;
}
.category .card{
    transition: all 0.2s;
}
.category .card-body {
    padding: 10px 12px;
}
.category .card:hover{
    border-color: var(--danger) !important;
    box-shadow: 0 0 10px 1px #efefef;
    color: var(--danger);
}
.osahan-listing-page-nav{
    position: sticky;
    top: 0;
    z-index: 9;
}
.osahan-listing-page-nav  a.dropdown-item {
    font-size: 14px;
    padding: 6px 19px;
}
.osahan-location p {
    font-size: 12px;
    width: 140px;
}
.osahan-location p.small {
    font-size: 9px;
}
.top-search-bar .form-control {
    font-size: 14px;
}
.top-search-bar .btn {
    font-size: 14px;
}
.osahan-top-search {
    height: 44px;
}
.osahan-second-nav .nav-link {
    font-size: 15px;
    padding: 15px 12px !important;
}
.navbar.navbar-dark.navbar-default.bg-danger.osahan-second-nav .nav-link {
    color: #fff;
}
.my-account-dropdown {
    margin-top: 10px !important;
    right: auto !important;
    left: 26px !important;
    width: 250px;
}

/* Homepage */
.homepage-nav-one-header {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
}
.homepage-nav-one-header .navbar {
    background: transparent !important;
    box-shadow: none !important;
}
.hero-slider-1 {
    background-color: #d7eff2;
}
.hero-slider-2 {
    background-color: #ffeaa2;
}
.hero-slider-main .slick-dots {
    bottom: 10px;
}
.top-picks-item h6.card-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 16px;
}
.bg-pink-light {
    background: #f5e5ec;
}

/* Banners */
.store-sidebar{
    margin-top: -114px;
}
.ch-50{
    height: 40px;
}
.btn-icon {
    font-size: 14px;
    padding: 0px;
    width: 40px;
    height: 40px;
    min-height: 40px !important;
    display: flex;
    color: #0d6efd;
    align-items: center;
    justify-content: center;
}
.feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    font-size: 1.5rem;
}
.icon-xl {
    height: 3.5rem;
    line-height: 3.5rem;
    width: 3.5rem;
}
.svg-border-rounded svg {
    height: 3rem;
    width: 100%;
}
.banner-icon {
    width: 48px;
}

.product-box{
    height: 150px;
    width: auto;
    border: 1px solid #e5e5e5;
}
.osahan-badge{
    font-size: 10px;
    letter-spacing: 0.8px;
    line-height: 13px;
    padding: 5px 4px 4px 4px;
    border-radius: 0 0 6px 6px;
}
.osahan-badge b {
    font-size: 12px;
}

/* Product Detail */
.why-shop-img{
    width: 60px;
}
.sticky-sidebar {
    position: sticky;
    top: 0px;
}
.sticky-sidebar2 {
    position: sticky;
    top: 0px;
}
.qr-code{
    width: 85px;
}
.small-img .osahan-arrow{
    z-index: 9;
}
.small-img:after {
    content: "";
    position: absolute;
    right: 0;
    width: 40px;
    background: #000;
    top: 0;
    bottom: 0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.99) 99%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.99) 99%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.99) 99%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.small-img:before {
    content: "";
    position: absolute;
    left: 0;
    width: 40px;
    background: #000;
    top: 0;
    bottom: 0;
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.01) 99%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.01) 99%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.01) 99%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    z-index: 1;
}

/* Listing */
.osahan-listing-page-nav-sidebar .nav-pills .nav-link{
    background-color: transparent;
    color: var(--light-dark);
    border-radius: 0;
    padding: 0px 1rem;
    border-left: 0px solid transparent;
}
.osahan-listing-page-nav-sidebar .nav-pills .nav-link img{
    width: 37px;
    background-color: var(--muted);
}
.osahan-listing-page-nav-sidebar .nav-pills .nav-link.active img{
    background-color: #ffdeda !important;
}
.osahan-listing-page-nav-sidebar .nav-pills .nav-link.active{
    border-left: 1px solid var(--danger) !important;
    color: #000;
    font-weight: 600;
}
.osahan-card-list .card-img-top {
    padding: 15px;
    transition: all 0.2s;
}
@media(hover: hover) and (pointer: fine) {
	.osahan-card-list:hover .card-img-top {
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
	}
	
	.product-item-content:hover .product-item-details .card-footer i {
		transform: scale(1.2);
		-webkit-transform: scale(1.2);
		transition: transform .2s ease;
		transform-origin: left top;
		-webkit-transform-origin: left top;
	}
}
.shop-list-page .osahan-card-list .card-img-top {
    height: 110px;
}

/* My Account */
.my-account-pills .nav-link{
    border: none;
    background-color: transparent;
    text-align: start;
    color: var(--light-dark);
    font-size: 11px;
    padding: 14px 5px;
    display: flex;
    position: relative;
    align-items: center;
    min-width: 73px;
}
.my-account-pills .nav-link.active:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #f8f9fa;
    right: -1px;
}
.my-account-pills .nav-link.active{
    background-color: #f8f9fa;
}
.my-account-pills .nav-link i {
    background: #d8d4fc;
    color: #593dff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    border-radius: 50px;
    font-size: 18px;
    margin-bottom: 8px;
}
.my-account-pills .nav-link:hover i, .my-account-pills .nav-link.active i {
    background: #593dff;
    color: #ffffff;
}
.order-img{
    width: 60px;
}
.my-account-main-page-sidebar .nav-link{
    border: none;
    background-color: #fff;
    text-align: start;
    color: var(--light-dark);
    font-size: 14px;
    padding: 18px 20px;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #eceded;
    border-radius: 0px;
}
.my-account-main-page-sidebar .nav-link.active{
    color: #fc3400;
    background: none !important;
}
.my-account-main-page-sidebar .nav-link i {
    background: #ffede8;
    color: #fc3400;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    border-radius: 50px;
}
.my-account-main-page-sidebar .nav-link:hover i, .my-account-main-page-sidebar .nav-link.active i {
    background: #fc3400;
    color: #ffffff;
}
.h-700{
    height: 700px;
}

/* Checkout */
.checkout-img{
    height: 27px;
    border: 1px solid #dadada;
    padding: 6px 6px;
    border-radius: 6px;
    background: #fff;
}
.osahan-checkout-item .img-fluid {
    height: 57px;
}
.osahan-netbanking .d-flex.justify-content-between.mb-2 {
    height: 42px;
    border: 1px solid #eaeaea;
    padding: 5px 5px;
    border-radius: 50px;
    width: 42px;
}
.upi-img{
    height: 20px;
    border: 1px solid #dadada;
    padding: 4px 4px;
    border-radius: 4px;
    background: #fff;
}
.osahan-radio-box-action  a {
    position: absolute;
    right: 16px;
    background: #fff;
    font-size: 11px;
    bottom: 12px;
}
.osahan-radio-box {
    position: relative;
}
.osahan-radio-box .form-check-label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}
.osahan-radio-box .form-check-input {
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    margin: 0px;
    border: 2px solid transparent;
    border-radius: 15px;
    background: no-repeat;
}
.osahan-radio-box .form-check-input:checked[type=radio] {
    background: none !important;
    border: 2px solid #000;
}
.osahan-radio-box .form-check-input:after {
    content: "";
    width: 19px;
    height: 19px;
    border-radius: 50px;
    background: #f8f9fa;
    position: absolute;
    right: 15px;
    top: 15px;
    border: 1px solid #dee2e6;
}
.osahan-radio-box .form-check-input:checked[type=radio]:before {
    content: "";
    width: 19px;
    height: 19px;
    border-radius: 50px;
    background: #ffffff;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    border: 5px solid var(--info);
}


/* Mobile Media */
@media (max-width: 912px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 100%;
    }
    .osahan-left {
        z-index: 9;
        left: -1px;
    }
    .osahan-right {
        right: -2px;
    }
    .osahan-arrow {
        width: 22px;
        height: 23px;
        font-size: 11px;
    }
    .osahan-listing-page-nav .navbar-toggler {
        padding: 0 0;
        position: absolute;
        top: -37px;
        right: 41px;
    }
    .osahan-listing-page-nav .container {
        padding: 0px;
    }
    .osahan-listing .osahan-listing-page-nav-sidebar {
        padding: 0px !important;
    }
    .osahan-listing .container {
        padding: 0px;
    }
    .product-detail-page .sticky-sidebar2.border-start.overflow-auto.vh-100.p-5 {
        padding: 0 0 50px 0px !important;
        border: none !important;
        height: auto !important;
    }
    .product-detail-page .col-lg-6.col-12.py-5 {
        padding-bottom: 10px !important;
    }
    .store-sidebar {
        margin-top: 0px;
    }
    .my-account-dropdown li.d-flex.align-items-center.gap-2.bg-light.p-3.mt-2 img {
        width: 32px;
        margin-bottom: auto;
        margin-top: 2px;
    }
    .my-account-dropdown {
        margin-top: 10px !important;
        right: 0 !important;
        left: 0 !important;
        width: 100%;
    }
	
	.w-md-100 {
		width: 100%!important;
	}
}

@media (max-width: 576px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 100%;
    }
    .osahan-location {
        padding-right: 0px !important;
    }
    .osahan-header ul.list-inline li.list-inline-item:first-child a.p-3 {
        padding-left: 0px !important;
    }
    .osahan-location p {
        font-size: 12px;
        width: 100%;
    }
    .top-search-bar input {
        border: none;
        box-shadow: none !important;
    }
    .shop-category .osahan-arrow{
        display: none !important;
    }
    .osahan-left {
        z-index: 9;
        left: -1px;
    }
    .osahan-right {
        right: -2px;
    }
    .osahan-arrow {
        width: 22px;
        height: 23px;
        font-size: 11px;
    }
    .osahan-listing .osahan-listing-page-nav-sidebar {
        padding: 0px !important;
    }
    .osahan-listing .container {
        padding: 0px;
    }
    .osahan-listing-page-nav .navbar-toggler {
        padding: 0 0;
        position: absolute;
        top: -37px;
        right: 41px;
    }
    .osahan-listing-page-nav .container {
        padding: 0px;
    }
 
    .product-detail-page .sticky-sidebar2.border-start.overflow-auto.vh-100.p-5 {
        padding: 0 0 50px 0px !important;
        border: none !important;
        height: auto !important;
    }
    .product-detail-page .col-lg-6.col-12.py-5 {
        padding-bottom: 10px !important;
    }

    .shop-list-page .text-truncate {
        width: 160px;
    }
    .shop-list-page .card-footer {
        flex-direction: column;
        gap: 5px !important;
    }
    .shop-list-page .card-footer br {
        display: none;
    }
    .shop-list-page .card-footer del {
        margin-right: 7px;
    }
    .shop-list-page .osahan-card-list .card-img-top {
        height: 91px;
    }
    .store-sidebar {
        margin-top: 0px;
    }
    .my-account-dropdown li.d-flex.align-items-center.gap-2.bg-light.p-3.mt-2 img {
        width: 32px;
        margin-bottom: auto;
        margin-top: 2px;
    }
    .my-account-dropdown {
        margin-top: 10px !important;
        right: 0 !important;
        left: 0 !important;
        width: 100%;
    }
}

.header-logo {
	margin-bottom: 15px;
}

.bg-orange {
	background-color: #F0B600;
}

.text-orange {
	color: #F0B600;
}

.btn-outline-light-dark{
    border-color: var(--light-dark) !important;
    color: var(--light-dark) !important;
}
.btn-outline-light-dark:hover{
    background-color: var(--info) !important;
    color: #FFF !important;
}

.btn-outline-light-dark:hover > i {
    color: #FFF !important;
}

.btn-danger {
	color: #000 !important;
}

.menu-item.collapsed {
	color: #656565!important;
}

.menu-item:not(.collapsed), .menu-item:hover {
	color: var(--info)!important;
}

.menu-item > i {
	transition: transform 0.2s ease-in-out;
}

.menu-item:not(.collapsed) > i {
	transform: rotate(180deg);
}

@media(hover: hover) and (pointer: fine) {
	.btn-danger:hover {
		background-color: var(--info)!important;
		color: #FFF !important;
		border-color: var(--info) !important;
	}
}

.text-info {
	color: var(--info)!important;
}

.bg-info {
	background-color: var(--info)!important;
	color: #FFF !important;
}

.logo-menu {
	height: 100px;
}

.menu-header {
	flex-direction: column;
}

.menu-tagline {
	width: 60%;
	flex-direction: column;
	margin-top: 15px;
	margin-bottom: 25px;
	padding-top: 15px;
	text-align: center;
	border-top: solid 1px #000;
	gap: 10px;
}

.menu-tagline-left {
	text-align: left;
}

.menu-tagline-right {
	text-align: right;
}

.menu-tagline-highlight {
	border-radius: 15px;
	padding: 3px 10px 3px 10px;
}

.menu-tagline h6 {
	font-size: 1.1rem!important;
}

.cart-body {
	display: flex;
	flex-direction: column;
}

.cart-item {
	width: 100%;
}

.cart-body, .cart-total, .cart-header, .cart-footer {
	width: 100%;
	max-width: 650px;
	margin: auto;
}

@media (min-width: 992px) {
	.cart-body {
		margin: inherit;
	}
}

.PhoneInputInput, .PhoneInputInput:focus {
	border: none;
	outline: none;
}

.PhoneInputCountrySelectArrow {
	display: none!important;
}

.PhoneInputCountrySelect.disabled {
	pointer-events: none;
}

.PhoneInputCountryIcon {
	box-shadow: none!important;
}

.textarea-container textarea, .textarea-container textarea:focus {
	border: none;
	outline: none;
	padding: 0!important;
	margin-top: 1.625rem;
}

.textarea-container label {
	font-size: 13px;
}

div.form-control:has(> textarea:focus), div.form-control:has(> input:focus), div.form-control:has(#react-select-3-input:focus) {
	border-color: #86b7fe;
}

p.l-2, .l-2 > p{
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
    line-clamp: 2; 
	-webkit-box-orient: vertical;
}

.btn.addToCart {
	height: 32px;
}

.h-80 {
	--bs-offcanvas-height: 80%;
}

.h-90 {
	--bs-offcanvas-height: 90%;
}

.w-80 {
	width: 80%;
}

.fixed-b {
	position: fixed;
	bottom: 0;
	left: 0;
}

.product-detail-footer {
	height: 90px;
	z-index: 1000;
	position: relative;
}

.product-detail-footer-cart {
	transform: translateY(90px);
	transition: transform 330ms ease-in-out;
}

.product-detail-footer-cart.in-cart, .product-detail-footer-added.in-cart {
	transform: translateY(0px);
}

.product-detail-footer-added.in-cart {
	transform: translateY(-95px);
}

.product-detail-footer-added {
	transform: translateY(0px);
	transition: transform 330ms ease-in-out;
	transition-delay: 100ms;
	z-index: 999;
}

.cursor-pointer {
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none; 
}

.cursor-default {
	cursor: default;
	pointer-events: none;
}

.cart-empty {
	flex-direction: column;
}

.cart-empty i {
	font-size: 3rem;
	margin: 1rem;
}

.cart-highlight {
    -webkit-animation: target-fade 3s 1;
    -moz-animation: target-fade 3s 1;
	border-radius: 100px;
}

@keyframes target-fade {
    0% { background-color: rgba(221,221,221,.8); }
    100% { background-color: rgba(255,255,153,0); }
}
@-webkit-keyframes target-fade {
    0% { background-color: rgba(255,255,153,.8); }
    100% { background-color: rgba(255,255,153,0); }
}
@-moz-keyframes cart-fade {
    0% { background-color: rgba(255,255,153,.8); }
    100% { background-color: rgba(255,255,153,0); }
}

.product-item-container {
	position: relative;
}

.product-item-overlay.display-none {
	display: none;
}

.product-item-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #e7e8e777;
	z-index: 2;
}

.product-item-out-stock span {
	font-family: 'Gotham-Book';
}

.product-item-image img {
	padding: 15px;
	border-radius: 0;
}

@media (max-width: 992px) {
	
	.product-item-content {
		display: flex;
		flex-direction: row;
	}
	
	.product-item-image {
		margin: 15px;
		display: flex;
		align-items: center;
		flex: 0 0 30%;
	}
	
	.product-item-image img {
		padding: 0!important;
	}
	
	.product-item-details {
		display: flex;
		flex-direction: column;
		padding: 15px 0 15px 0;
		min-width: 0;
	}
	
	.product-item-details .card-footer {
		padding-bottom: 0!important;
	}
}

.quantity-field {
	background: transparent;
}

.vh-100 {
	height: 100dvh!important;
}

.mvh-100 {
	max-height: 100dvh;
}

.h-0 {
	height: 0px;
}

.border-error, .border-error>.form-control {
	border-color: red;
}

.text-overflow-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.loading-button {
	height: 57px;
}

.loading-button.loading {
	background-color: #689ecb!important;
	border-color: #689ecb!important;
	pointer-events: none;
}

.btn-danger.cursor-default {
	background-color: #bdbcb9!important;
	border-color: #bdbcb9!important;
}

.bg-light-gray {
	background-color: #e2e2e2!important;
	border-color: #c6ceda!important;
}

.bg-light-gray > .border-end {
	border-color: #c6ceda!important;
}

.customBottomSheet div[data-rsbs-footer="true"] {
	padding: 0;
	cursor: default;
	box-shadow: none;
}

.customBottomSheet a:focus, .customBottomSheet button:focus {
	outline: none;
	box-shadow: none;
}

.fs-10 {
	font-size: 10rem!important;
}

.accordion-button::after {
	margin-bottom: auto;
}

.modal-dialog-centered {
	height: calc(100% - var(--bs-modal-margin) * 2)!important;
}

.address-input div:has(input)::after {
	display: none;
}

.cms-container a {
	color: var(--info);
}

.icon-18 {
	width: 30px;
	height: 30px;
	border: 2px solid;
}

#deliveryConfirm .border-dark {
	border: 1px solid;
}

#deliveryConfirm .delivery-confirm-item {
	min-width: 250px;
}

.flex-basis-0 {
	flex-basis: 0;
}

.cgv-input {
	width: 3em!important;
	height: 1.5em!important;
}

@media (max-width: 992px) {
	.cgv-input {
		width: 4em!important;
		height: 1.5em!important;
	}
}

.cgv-error {
	border: 1px solid red;
	border-radius: 2rem;
}

.cookie-banner {
	transition: transform 300ms ease-in-out;
	transform: translateY(150%);
	z-index: 2;
}

.cookie-banner.visible {
	transform: translateY(0);
}

.cookie-banner-content {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	margin-top: -1rem;
}

.cookie-banner-content img {
	max-height: 120px;
	margin-bottom: 1rem;
	aspect-ratio: 1 / 1;
}

.cookie-banner-content p {
	font-size: 0.7rem;
}

@media (max-width: 1076px) {
	.cookie-banner-content {
		flex-direction: column;
		align-items: center;
		gap: 0.5rem!important;
		text-align: left !important;
		margin-top: 0;
	}
	
	.cookie-banner-content img {
		max-width: 20%;
	}
	
	.cookie-banner p {
		margin-top: 0.5rem !important;
		width: 100%;
	}
}

.text-error {
	color: #f8325f;
}

#tidio-chat-iframe { 
	bottom: 75px !important;
}
